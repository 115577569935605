import React, { Component } from 'react';

class Web3Banner extends Component {

  constructor(props) {
    super(props);
    this.state = {
      webNetwork: "Main",
      displayBanner: true
    }

    this.triggerCloseBanner = this.triggerCloseBanner.bind(this);
  }

  triggerCloseBanner() {
    console.log("Clicked on Web3 Banner Box close icon");
    this.setState({ displayBanner: false });
  }

  render() {
    return (
      <div id="metamask-info-banner" className={this.state.displayBanner ? "hidden-xs" : "vis-hidden"}>
        <span className="close" onClick={this.triggerCloseBanner}></span>
        <div className="info-banner-content">
          <strong>Ethereum Network: </strong><span id="metamask-network">{this.props.displayConnectBtn ? "" : this.props.web3Network}</span><br/>
          <strong>Address: </strong><a href={"https://etherscan.io/address/" + this.props.walletAddress} id="metamask-address" target="_blank" rel="noreferrer">{this.props.displayConnectBtn ? "" : this.props.walletAddress}</a>
          <br/>
          <strong>Status: </strong><span id="metamask-status">{this.props.displayConnectBtn ? "Not Connected" : "Connected"}</span><br/>
        </div>
      </div>
    );
  }
}

export default Web3Banner;