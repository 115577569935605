
import React, { Component } from 'react';

const wccatAddress = process.env.REACT_APP_WCCAT_ADDRESS;
const targetNetwork = process.env.REACT_APP_TARGET_NETWORK;

class WrapModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step1Complete: false,
      step1InProgress: false,
      step1HashAvailable: false,
      step2Complete: false,
      step2InProgress: false,
      step2HashAvailable: false
    }

    this.triggerForSaleToWrapper = this.triggerForSaleToWrapper.bind(this);
    this.triggerDepositAndMint = this.triggerDepositAndMint.bind(this);
    this.checkForSaleWrapperAllowed = this.checkForSaleWrapperAllowed.bind(this);
    this.checkIsWrapped = this.checkIsWrapped.bind(this);
    this.triggerClose = this.triggerClose.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.catId !== undefined && (this.props.catId !== prevProps.catId)) {
      this.checkForSaleWrapperAllowed((allowedStatus) => {
        console.log("ForSaleToWrapper Allowed => " + allowedStatus);
        this.setState({
          step1Complete: allowedStatus
        })
      })

      this.checkIsWrapped((wrappedStatus, owner) => {
        console.log("Wrapped Status => " + wrappedStatus);
        this.setState({
          step2Complete: wrappedStatus
        })
      })
    }
  }

  checkForSaleWrapperAllowed(cb) {
    if (this.props.ccat) {
      var cryptoCat = this.props.ccat;   
      cryptoCat.checkForSaleToWrapperStatus(this.props.catId, cb);
    } else {
      cb(false);
    }
  }

  checkIsWrapped(cb) {
    if (this.props.ccat) {
      var cryptoCat = this.props.ccat;   
      cryptoCat.isWrapped(this.props.catId, cb);
    } else {
      cb(false, "0x0");
    }
  }

  triggerForSaleToWrapper() {
    var that = this;
    if (this.props.ccat) {
      var cryptoCat = this.props.ccat;
      console.log("Check CCAT Contract", cryptoCat);
      // cryptoCat.checkForSaleToWrapperStatus(this.props.catId, wccatAddress);

      this.setState({
        step1InProgress: true
      });

      cryptoCat.callCatForSaleToAddress(this.props.catId, this.props.owner, wccatAddress, 
        (txHash) => {
          console.log("Transaction Sent! Tx hash: " + txHash);
          that.setState({
            step1HashAvailable: true
          });
        },
        (confirmReceipt) => {
          console.log("Transaction Confirmed! Tx receipt: " + confirmReceipt);
          that.setState({
            step1InProgress: false,
            step1Complete: true,
            step1HashAvailable: false
          });
        },
        (err) => {
          console.log("Error Occurred", err);
          this.setState({
            step1InProgress: false,
            step1HashAvailable: false
          });
        }
      )
    }
  }

  triggerDepositAndMint() {
    var that = this;
    if (this.props.wccat) {
      var wrappedCryptoCat = this.props.wccat;
      console.log("Check WCCAT Contract", wrappedCryptoCat);
      // cryptoCat.checkForSaleToWrapperStatus(this.props.catId, wccatAddress);

      this.setState({
        step2InProgress: true
      });

      wrappedCryptoCat.callDepositAndMint(this.props.catId, this.props.owner,  
        (txHash) => {
          console.log("Transaction Sent! Tx hash: " + txHash);
          that.setState({
            step2HashAvailable: true
          })
        },
        (confirmReceipt) => {
          console.log("Transaction Confirmed! Tx receipt: " + confirmReceipt);
          that.setState({
            step2InProgress: false,
            step2Complete: true,
            step2HashAvailable: false
          });
        },
        (err) => {
          console.log("Error Occurred", err);
          this.setState({
            step2InProgress: false,
            step2HashAvailable: false
          });
        }
      )
    }
  }

  triggerClose() {
    this.setState({
      step1Complete: false,
      step1InProgress: false,
      step1HashAvailable: false,
      step2Complete: false,
      step2InProgress: false,
      step2HashAvailable: false
    });

    this.props.triggerReload();
  }

  render() {
    return (
      <div className="modal fade" id="wrapCatModal" tabIndex="-1" role="dialog" aria-labelledby="wrapCatModalCenter" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="wrapCatModalTitle">Wrap Your CryptoCat</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.triggerClose}></button>
            </div>
            <div className="modal-body" id="wrap-modal-body">
              <div className="row d-flex justify-content-center">
                { this.props.catId &&
                  <div className="col-6 col-lg-4 align-center">
                    <img id="wrap-image" alt="cryptocat" src={"https://cryptocats.thetwentysix.io/contents/images/cats/" + this.props.catId + ".png"} className="cat-img"></img>
                    <h4>Cat# {this.props.catId}</h4>
                  </div>
                }
              </div>            
              <div className="row align-center">
                <div className="col-12">
                    <p>Wrapping your CryptoCat makes it compatible to ERC721 token standards, which then allows you to view, sell and trade your cat on an ERC721 marketplace like OpenSea.</p>
                    <p><a target="_blank" rel="noreferrer" href="https://etherscan.io/address/0xd0e7bc3f1efc5f098534bce73589835b8273b9a0">Click here</a> to view our verified Wrapped CryptoCat contract on Etherscan</p>
                    <p>It is a 2-step process, and requires you to use your Metamask wallet to submit 2 transactions by clicking buttons in steps 1 and then 2 below.</p>                    
                </div>
              </div>
              <div className="row align-center" id="wrap-steps">
                <div className="col-6 align-center">
                  <div className="step-title">Step 1</div>
                  <div className="step-text">Authorise CryptoCat token (CCAT) contract to transfer your token to our wrapper contract</div>
                  { !this.state.step1Complete && !this.state.step1InProgress &&
                    <button className="btn btn-sm btn-primary" onClick={this.triggerForSaleToWrapper}>Allow Transfer</button>
                  }
                  { this.state.step1Complete && !this.state.step1InProgress &&
                    <div className="done-label">Done!</div>
                  }
                  { this.state.step1InProgress && 
                    <div>
                      <img src="loading-spinner.gif" className="wrap-tx-spinner" alt="spinner" />
                      <div>
                        { !this.state.step1HashAvailable && 
                          <i>(please approve in metamask...)</i>
                        }
                        { this.state.step1HashAvailable && 
                          <i>(waiting for network confirmation...)</i>
                        }
                      </div>
                    </div>
                  }
                </div>
                <div className="col-6 align-center">
                  <div className="step-title">Step 2</div>
                  <div className="step-text">Transfer cat to wrapper contract and receive equivalent Wrapped CryptoCat token (WCCAT)</div>
                  { !this.state.step2Complete && !this.state.step2InProgress &&
                    <button className="btn btn-sm btn-primary" onClick={this.triggerDepositAndMint} disabled={!this.state.step1Complete} >Receive WCCAT Token</button>
                  }
                  { this.state.step2Complete && !this.state.step2InProgress &&
                    <div className="done-label">Done!</div>
                  }
                  { this.state.step2InProgress && 
                    <div>
                      <img src="loading-spinner.gif" className="wrap-tx-spinner" alt="spinner" />
                      <div>
                      { !this.state.step2HashAvailable && 
                          <i>(please approve in metamask...)</i>
                        }
                        { this.state.step2HashAvailable && 
                          <i>(waiting for network confirmation...)</i>
                        }
                      </div>
                    </div>
                  } 
                </div>
              </div>
              { this.state.step1Complete && this.state.step2Complete &&
                <div className="row align-center" id="wrap-successful">
                  <div className="col-12">
                    <h5>All done! View your CryptoCat on our <a target="_blank" rel="noreferrer" href={"https://" + (targetNetwork === "MAINNET" ? "" : "testnets.") + "opensea.io/assets/" + wccatAddress + "/" + this.props.catId}>Official OpenSea Collection</a></h5>
                  </div>
                </div>              
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WrapModal;