// import './App.css';
import React, { Component } from 'react';
import axios from 'axios';
import Header from './components/Header';
import WrapModal from './components/WrapModal';
import UnwrapModal from './components/UnwrapModal';
import FilterButtons from './components/FilterButtons';
import ConnectMetamaskButton from './components/ConnectMetamaskButton';
import CatCard from './components/CatCard';
import Web3Banner from './components/Web3Banner';
import Web3 from 'web3';
import CryptoCat from './web3/CryptoCat';
import WrappedCryptoCat from './web3/WrappedCryptoCat';

import detectEthereumProvider from '@metamask/detect-provider';

const ccatAddress = process.env.REACT_APP_CCAT_ADDRESS;
const wccatAddress = process.env.REACT_APP_WCCAT_ADDRESS;
const ccatApiUrl = process.env.REACT_APP_API_URL + "/listing";

const requiredConfirmations = parseInt(process.env.REACT_APP_TX_CONFIRMATIONS);
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterType: "CCAT",
      ccatOwnedByAddress: [],
      wccatOwnedByAddress: [],
      catIdToWrap: "",
      catIdToUnwrap: "",
      walletAddress: "",
      showWeb3ConnectButton: true,
      ccatContractAddress: ccatAddress,
      wccatContractAddress: wccatAddress,
      catListIsLoading: false
    }

    console.log("CCAT Address: " + ccatAddress);
    console.log("WCCAT Address: " + wccatAddress);

    this.toggleFilterType = this.toggleFilterType.bind(this);
    this.showWrapModal = this.showWrapModal.bind(this);
    this.showUnwrapModal = this.showUnwrapModal.bind(this);
    this.connectWeb3Account = this.connectWeb3Account.bind(this);
    this.detectAndLoadWeb3Provider = this.detectAndLoadWeb3Provider.bind(this);
    this.getShortWalletAddress = this.getShortWalletAddress.bind(this);
    this.getCCATowned = this.getCCATowned.bind(this);
    this.getWCCATowned = this.getWCCATowned.bind(this);
    this.reloadCCATList = this.reloadCCATList.bind(this);
    this.reloadWCCATList = this.reloadWCCATList.bind(this);
    this.reloadCats = this.reloadCats.bind(this);
  }

  componentDidMount() {
    this.detectAndLoadWeb3Provider();
  }

  reloadCats() {
    if (this.filterType === "CCAT") {
      this.reloadCCATList();
    } else {
      this.reloadWCCATList();
    }
  }

  reloadCCATList() {
    var that = this;
    that.setState({
      catListIsLoading: true
    });
    that.getCCATowned((that.state.walletAddress), (ccatList) => {
      that.setState({
        ccatOwnedByAddress: ccatList,
        catListIsLoading: false
      })
    });
  }

  reloadWCCATList() {
    var that = this;
    that.setState({
      catListIsLoading: true
    });
    that.getWCCATowned((that.state.walletAddress), (wccatList) => {
      that.setState({
        wccatOwnedByAddress: wccatList,
        catListIsLoading: false
      })
    });
  }

  getCCATowned(address, callback) {
    axios.get(ccatApiUrl + "/ccat/" + address)
    .then((result) => {
      console.log("CCAT API resp:", result);
      if (result !== undefined && result.data !== undefined && result.data.ccat !== undefined) {
        var catIds = Object.keys(result.data.ccat);
        console.log("CCAT list => ", catIds);
        callback(catIds);
      } else {
        callback([]);
      }
    })
    .catch((err) => {
      console.log("error retrieving CCAT list", err);
      callback([]);
    })
  }

  getWCCATowned(address, callback) {
    axios.get(ccatApiUrl + "/wccat/" + address)
    .then((result) => {
      console.log("WCCAT API resp:", result);
      if (result !== undefined && result.data !== undefined && result.data.ccat !== undefined) {
        var catIds = Object.keys(result.data.ccat);
        console.log("WCCAT list => ", catIds);
        callback(catIds);
      } else {
        callback([]);
      }
    })
    .catch((err) => {
      console.log("error retrieving WCCAT list", err);
      callback([]);
    })
  }

  async detectAndLoadWeb3Provider() {
    const provider = await detectEthereumProvider();
 
    if (provider) {
      console.log('Ethereum successfully detected!')
      console.log(provider);

      const web3 = new Web3(provider);

      var cryptoCat = new CryptoCat(web3, ccatAddress, wccatAddress, requiredConfirmations);
      var wrappedCryptoCat = new WrappedCryptoCat(web3, wccatAddress, requiredConfirmations);

      const chainId = await provider.request({
        method: 'eth_chainId'
      })

      var networkName = "";

      console.log("Web3 Chain id => " + chainId);

      if (chainId ==="0x4") {
        networkName = "Rinkeby"
      } else if (chainId ==="0x1") {
        networkName = "Mainnet"
      } else {
        networkName = "Unknown";
      }

      this.setState({
        web3Provider: provider,
        web3Connected: true,
        web3NetworkId: chainId,
        web3Network: networkName,
        ccatContract: cryptoCat,
        wccatContract: wrappedCryptoCat
      });

      provider.request({ method: 'eth_accounts' })
      .then((accounts) => {
        console.log("Connected MM Accounts : ", accounts);
        this.connectWeb3Account(accounts)
      })
      .catch((err) => {
        // Some unexpected error.
        console.error(err);
      });

      provider.on('chainChanged', this.handleWeb3ProviderChainChanged);

      provider.on('accountsChanged', this.connectWeb3Account);

    } else {
      console.error('Please install MetaMask!');
    }
  }

  handleWeb3ProviderChainChanged() {
    window.location.reload();
  }

  showWrapModal(catId) {
    this.setState({ catIdToWrap: catId });
    console.log("Wrap Cat modal open for cat #" + catId);
  }

  showUnwrapModal(catId) {
    this.setState({ catIdToUnwrap: catId });
    console.log("Unwrap Cat modal open for cat #" + catId);
  }

  async connectWeb3Account(accounts) {
    var that = this;
    if (accounts[0]) {
      console.log("Wallet Address from MM => " + accounts[0]);
      this.setState({
        walletAddress: accounts[0],
        showWeb3ConnectButton: false,
        catListIsLoading: true
      });
      this.getCCATowned(accounts[0], (ccatList) => {
        that.setState({ 
          ccatOwnedByAddress: ccatList,
          catListIsLoading: false
        })        
      });
      this.getWCCATowned(accounts[0], (wccatList) => {
        that.setState({ 
          wccatOwnedByAddress: wccatList,
          catListIsLoading: false
        })        
      });
    } else {
      console.log("Empty Wallet Address from MM");
      this.setState({
        showWeb3ConnectButton: true,
        catListIsLoading: false
      });  
    }

  }

  toggleFilterType(catType) {
    var that = this;
    console.log("Clicked filter by => " + catType);
    that.setState({
      catListIsLoading: true
    })

    if (catType === "CCAT") {
      if (!that.state.showWeb3ConnectButton) {
        this.getCCATowned(that.state.walletAddress, (ccatList) => {
          that.setState({ 
            ccatOwnedByAddress: ccatList,
            catListIsLoading: false
          })        
        });        
      }
    
      that.setState({ 
        filterType: "CCAT"
      })
    } else if (catType === "WCCAT") {
      if (!that.state.showWeb3ConnectButton) {
        that.getWCCATowned(that.state.walletAddress, (wccatList) => {
          that.setState({ 
            wccatOwnedByAddress: wccatList,
            catListIsLoading: false
          })        
        });
      }

      that.setState({ 
        filterType: "WCCAT" 
      })
    }
  }

  getShortWalletAddress() {
    return this.state.walletAddress.substring(0,5) + "..." + this.state.walletAddress.substring(this.state.walletAddress.length - 4)
  }

  render() {
    return (
      <div className="App">
        <WrapModal triggerReload={this.reloadCats} catId={this.state.catIdToWrap} owner={this.state.walletAddress} ccat={this.state.ccatContract} wccat={this.state.wccatContract}/>
        <UnwrapModal triggerReload={this.reloadCats} catId={this.state.catIdToUnwrap} owner={this.state.walletAddress} wccat={this.state.wccatContract}/>
        <Web3Banner web3Network={this.state.web3Network} walletAddress={this.state.walletAddress} displayConnectBtn={this.state.showWeb3ConnectButton} connectWeb3Account={this.connectWeb3Account}/>
        <Header />
        <div className="container-fluid">
          <div className="menu d-none d-md-block">
            <div className="list-group">
                <a target="_blank" rel="noreferrer" href="https://cryptocats.thetwentysix.io/" className="list-group-item hvr-underline-from-left text-uppercase">CRYPTOCATS VINTAGE SITE</a>
                <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/wrappedcryptocats" className="list-group-item hvr-underline-from-left text-uppercase">OPENSEA WRAPPED CRYPTOCATS COLLECTION</a>                
                <div id="contact-sidebar" className="list-group-item">
                  <address>
                    <div className="menu-title-text">
                      CryptoCat Contract
                    </div>
                    <a target="_blank" rel="noreferrer" href="https://etherscan.io/address/0x088c6ad962812b5aa905ba6f3c5c145f9d4c079f">0x088C6Ad962812b5Aa905BA6F3c5c145f9D4C079f</a><br/>
                    <div className="menu-title-text">
                      Wrapped CryptoCat Contract
                    </div>
                    <a target="_blank" rel="noreferrer" href="https://etherscan.io/address/0xd0e7bc3f1efc5f098534bce73589835b8273b9a0">0xd0e7Bc3F1EFc5f098534Bce73589835b8273b9a0</a><br/>
                    <br/><br/>
                    <strong>JOIN THE CONVERSATION</strong><br/>
                    <a href="https://twitter.com/CryptoCats26?ref_src=twsrc%5Etfw">Follow us on twitter @cryptocats26 for latest news</a>
                    <br/><br/>
                    <a href="mailto:cryptocats@thetwentysix.io">cryptocats@thetwentysix.io</a>
                    <br/><br/><br/>
                    <strong>LICENSE DETAILS</strong><br/>
                    <span>Whilst you are the owner of a CryptoCat NFT you can use the artwork for your own personal, non-commercial use including displaying in digital or physical formats. For commercial uses, please reach out to us for approval first.</span>
                  </address>
                </div>
            </div>
          </div>
        </div>
        <div className="main">
          <div className="d-xs-block d-sm-block d-md-none">
            <nav id="mobile-menu" className="navbar navbar-light bg-transparent">
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMobileToggle" aria-controls="navbarMobileToggle" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
            </nav>
            <div className="collapse" id="navbarMobileToggle">
              <div className="bg-light p-3 mb-2 align-center">
                <a target="_blank" rel="noreferrer" href="https://cryptocats.thetwentysix.io/" className="list-group-item hvr-underline-from-left text-uppercase p-1">CRYPTOCATS VINTAGE SITE</a>
                <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/wrappedcryptocats" className="list-group-item hvr-underline-from-left text-uppercase p-1">OPENSEA WRAPPED CRYPTOCATS COLLECTION</a> 
              </div>
            </div>            
          </div>

          <div className="body-header">
            <div id="alert-content" className="alert alert-info" role="alert" style={{display: "none"}}></div>

            { this.state.showWeb3ConnectButton &&
              <div className="align-center m-top-10pct">
                <p>Click to connect Metamask wallet and load your CryptoCats</p>
                <ConnectMetamaskButton connectWeb3Account={this.connectWeb3Account} /> 
              </div>
            }

            <div id="utilities-section" className="container">
              <div className="row filter-btn-container">
                  <div className="filter-btn-inner">
                  { !this.state.showWeb3ConnectButton &&
                    <FilterButtons toggleFilter={this.toggleFilterType} filterValue={this.state.filterType} catListIsLoading={this.state.catListIsLoading} />
                  }
                  </div>
              </div>
            </div>

            { this.state.filterType === "CCAT" &&
              this.state.ccatOwnedByAddress.length === 0 &&
              !this.state.showWeb3ConnectButton &&
              <div className="no-cats-label align-center">You have no CCAT tokens</div>
            }

            { this.state.filterType === "WCCAT" &&
              this.state.wccatOwnedByAddress.length === 0 &&
              !this.state.showWeb3ConnectButton &&
              <div className="no-cats-label align-center">You have no WCCAT tokens</div>
            }

            <div className="cards_container">
                {  this.state.filterType === "CCAT" &&
                  this.state.ccatOwnedByAddress &&
                  this.state.ccatOwnedByAddress.map((idValue, index) => {
                    console.log("CAT ID " + idValue);
                    return <CatCard key={index} catId={idValue} showWrapModal={this.showWrapModal} cardType="CCAT" />
                  })
                }           

                {  this.state.filterType === "WCCAT" &&
                  this.state.wccatOwnedByAddress &&
                  this.state.wccatOwnedByAddress.map((idValue, index) => {
                    console.log("CAT ID " + idValue);
                    return <CatCard key={index} catId={idValue} showUnwrapModal={this.showUnwrapModal} cardType="WCCAT" />
                  })
                }

            </div>

            <div id="mobile-info-section" className="container d-xs-block d-sm-block d-md-none">
            <div className="menu-title-text">
              CryptoCat Contract
            </div>
            <a target="_blank" rel="noreferrer" href="https://etherscan.io/address/0x088c6ad962812b5aa905ba6f3c5c145f9d4c079f">0x088C6Ad962812b5Aa905BA6F3c5c145f9D4C079f</a><br/>
            <div className="menu-title-text">
              Wrapped CryptoCat Contract
            </div>
            <a target="_blank" rel="noreferrer" href="https://etherscan.io/address/0xd0e7bc3f1efc5f098534bce73589835b8273b9a0">0xd0e7Bc3F1EFc5f098534Bce73589835b8273b9a0</a><br/>
            <br/><br/>
            <strong>JOIN THE CONVERSATION</strong><br/>
            <a href="https://twitter.com/CryptoCats26?ref_src=twsrc%5Etfw">Follow us on twitter @cryptocats26 for latest news</a>
            &nbsp;or&nbsp;
            <a href="mailto:cryptocats@thetwentysix.io">email us at cryptocats@thetwentysix.io</a>
            <br/><br/>
            <strong>LICENSE DETAILS</strong><br/>
            <span>Whilst you are the owner of a CryptoCat NFT you can use the artwork for your own personal, non-commercial use including displaying in digital or physical formats. For commercial uses, please reach out to us for approval first.</span>
            </div>
          </div>
        </div>

      </div>
    );
  }

}

export default App;
