import React, { Component } from 'react';

class FilterButtons extends Component {

  render() {
    return (
      <div>
        <span className="filter-label">Filter by type:&nbsp;</span>
        <div id="filter-release-btn-group" className="btn-group">
          <button 
            className={this.props.filterValue === "CCAT" ? "btn-sm btn--primary cbp-filter-item active" : "btn-sm btn--primary cbp-filter-item"}
            onClick={() => this.props.toggleFilter("CCAT")}>
              My Vintage Cats
          </button>
          <button 
            className={this.props.filterValue === "WCCAT" ? "btn-sm btn--primary cbp-filter-item btn-last active" : "btn-sm btn--primary cbp-filter-item btn-last" }
            onClick={() => this.props.toggleFilter("WCCAT")}>
              My Wrapped Cats
          </button>
        </div>
        { this.props.catListIsLoading &&
          <div id="filter-loading-area">
            <i>loading cats...</i>
            <img src="spinner-bg.gif" alt="spinner" id="web3-loading-spinner" />            
          </div>
        }
                          
    </div>
    );
  }
}

export default FilterButtons;